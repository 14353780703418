import React from 'react'
import NoRecord from './NoRecord';
export default function IncomeCard(props) {

    return (
        <>
            {props.data != null ? props.data.map((item, i) => {
                const PackageList = (
                    <div className="billddiscription" style={{
                        display: 'flex',background:'linear-gradient(220.55deg, rgba(219, 151, 5, 0.93), rgb(40, 167, 69))', justifyContent: 'space-between', boxShadow: '2px 3px 10px #bab8b8',
                        borderRadius: 8, padding: 3, margin: '12px 0px', marginLeft: 4, width: '100%'
                    }}>
                        <div><p style={{ margin: '7px 0px', fontSize: 12, color: '#000' }} className="TransactionNote">
                            <img src="https://cdn3d.iconscout.com/3d/premium/thumb/tron-crypto-coin-9579312-7746691.png?f=webp" height="25px" />
                            <span style={{ fontSize: 14, position: 'absolute', marginTop: 5, marginLeft: 7 }} className="UserMobileNo">{item.TransactionNote}</span>
                            <span style={{ fontSize: 14, position: 'absolute', marginTop: 5, marginLeft: 230 }} className="UserMobileNo"><span>{item.Amount}</span></span>

                            <span style={{ fontSize: 13, position: 'absolute', marginTop: 25, marginLeft: 230 }} className="UserMobileNo"><span>{item.Status}</span></span>
                        </p>
                            <h6 style={{ margin: '5px 0px', fontSize: 12, color: '#000' }} className="TransactionDate">{item.TransactionDate}</h6>

                        </div>
                        <div className="billnumber" style={{ marginRight: 7 }}><h3 style={{ margin: '16px 0px', fontSize: 15, color: '#000' }}
                            className="TransactionAmount"></h3></div>

                    </div>
                );
                return PackageList;
            }) : <NoRecord />}
        </>
    );
}
