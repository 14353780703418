import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import PackageCard from './PackageCard';
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import toast, { Toaster } from 'react-hot-toast';
export default function SignUp() {
    let navigate = useNavigate();
    let params = (new URL(document.location)).searchParams;

    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        Name: 'Digital Miner',
        MobileNo: '',
        Password: '',
        ConfirmPassword: '123456',
        TransactionPassword: '123456',
        SponsorId: params.get("ref") ? params.get("ref") : ""
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    const doRegistration = () => {
        // if (allValues.Name == "") {
        //     toast.error('Enter Nick Name');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.MobileNo == "") {
            toast.error('Enter Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.MobileNo.toString().length < 10 || allValues.MobileNo.toString().length > 15) {
            toast.error('Enter Valid Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Password.');
            inputEls.current[2].focus();
            return;
        }
        // if (allValues.ConfirmPassword == "") {
        //     toast.error('Enter Confirm Password.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.ConfirmPassword != allValues.Password) {
        //     toast.error('Password and Confirm Password do not matched.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.TransactionPassword == "") {
        //     toast.error('Enter Withdrawal Password.');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.SponsorId == "") {
            toast.error('Enter Referral Code.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.Name = allValues.Name;
        param.SponsorUserName = allValues.SponsorId;
        param.Password = allValues.Password;
        param.ConfirmPassword = allValues.ConfirmPassword;
        param.TransactionPassword = allValues.TransactionPassword;
        param.ConfirmTransactionPassword = allValues.TransactionPassword;
        param.MobileNo = allValues.MobileNo;
        var obj = new Object();
        obj.procName = 'TronZRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Success');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (ConfirmpasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="css/login.css" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="container">
                <div className="screen">
                    <div className="screen__content">
                        <div className="login" style={{paddingTop:127}}>
                            <div className="logo">
                                <img src="img/logo.png?8" alt />
                            </div>
                            <div className="login__field" style={{marginTop:-28}}>
                                <i className="login__icon fa fa-user" />
                                <input id="txtMobileNo" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} type="number" name="MobileNo" className="login__input" placeholder="Mobile No." />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fa fa-lock" />
                                <input id="txtPassword" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} name="Password" type="password" className="login__input" placeholder="Password" />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fa fa-user" />
                                <input value={allValues.SponsorId ? allValues.SponsorId : ""} id="txtSponsorId" name="SponsorId" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="text" className="login__input" placeholder="Referral Code" />
                            </div>
                            <button className="button login__submit" type='button' onClick={doRegistration}>
                                <span className="button__text">Register Now</span>
                                <i className="button__icon fa fa-chevron-right arrow1" />
                            </button>
                        </div>
                        <div className="social-login">
                            <h5 className='registerbutton' onClick={() => routeChange('login')}>Login Now  <i className="fa fa-chevron-right" /></h5>
                            <div className="social-icons" style={{marginTop:-19}}>
                                <a href="https://t.me/#" className="social-login__icon fa fa-telegram" />
                                <a href="https://t.me/#" className="social-login__icon fa fa-support" />
                            </div>
                        </div>
                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4" />
                        <span className="screen__background__shape screen__background__shape3" />
                        <span className="screen__background__shape screen__background__shape2" />
                        <span className="screen__background__shape screen__background__shape1" />
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    
                    .logo {
                       width: 210px;
                        height: 140px;
                        margin: auto;
                        margin-top: -58px;
                        margin-left: -6px;
                    }
                    input{
                        font-size:15px!important;
                        color:#fff!important;
                    }
                    .registerbutton{
                        font-size: 20px;
                        margin-top: 72px;
                        color:#000;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
          `
                }}
            />
        </>
    )
}
