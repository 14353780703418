import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import axios from "axios";
import Loading from './Loading';
function UserProfile() {

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  //Handling Loading 
  const [isLoading, setIsLoading] = useState(false);

  const [profileInfo, setProfile] = useState({
    name: "",
    mobileNo: "",
    inviteCode: "",
    balance: 0,
    recharge: 0,
    withdraw: 0,
  })
  const GetProfileDetails = () => {
    setIsLoading(true);
    var param = new Object();
    param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
    var obj = new Object();
    obj.procName = 'GetMemberBasicDetails';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      //Setting  Details
      let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
      setProfile({
        mobileNo: xCode.ContactNo, inviteCode: xCode.UserName,
        balance: res[0].Balance,
        recharge: res[0].TotalWalletAmount,
        withdraw: res[0].TotalWithdrawAmount,
        name: res[0].ClientName
      });

    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }
  const LogOut = () => {
    localStorage.clear();
    routeChange("login");
  }
  useEffect(() => {
    GetProfileDetails();
  }, []);
  return (
    <>
      <div>
        {/* <div className="navbar">
          <BackButton path='home' />
          <span style={{ marginRight: '25%', marginTop: '-15px', fontSize: 17 }}>My Profile</span>
          <span />
        </div> */}
        <div class="container" style={{ background:'#1d1d1d',margin: 0, color: '#fff',boxShadow: '-1px 1px 12px 0px #ebebeb',padding:'4px'}}>

          <div className='row myrow' style={{ padding: 10, backgroundPosition: 'bottom' }}>
            <div className='col-4'>
              <img src="https://cdn.pixabay.com/animation/2023/03/23/17/00/17-00-52-997_512.gif" style={{ width: '97%', borderRadius: '50%' }} alt />
            </div>
            <div className='col-8' style={{ fontSize: '0.29rem', padding: '0px' }}>
              {/* <div style={{ position: 'absolute', fontSize: '0.3rem', right: '5%' }}><img onClick={LogOut} height={18} src="https://cdn-icons-png.flaticon.com/512/1629/1629082.png" /><br></br><span style={{ color: 'red', fontWeight: 'bold' }}>Log Out</span></div> */}
              <h5 style={{ fontSize: '18px', marginBottom: '0px', marginTop: '20px', color: '#fff' }}>{profileInfo.mobileNo}</h5>
              <p style={{ fontSize: '13px', color: '#fff' }}>Your Code: <span>{profileInfo.inviteCode}</span></p>
            </div>


          </div>
          <div className="row profileCard" style={{ borderRadius: 5, margin: 0, padding: '5px', color: '#fff', background: 'none', marginBottom: '20px', marginTop: '-35px' }}>
            <div className="col-4">
              <div style={{ fontSize: '0.25rem', marginBottom: '10px' }}>Wallet Balance</div>
              <h5 style={{ fontSize: '17px', marginBottom: '0px' }}>{profileInfo.balance} TRX</h5>
            </div>
            <div className="col-4">
              <div style={{ fontSize: '0.25rem', marginBottom: '10px' }}>Deposited</div>
              <h5 style={{ fontSize: '17px', marginBottom: '0px' }}>{profileInfo.recharge} TRX</h5>
            </div>
            <div className="col-4">
              <div style={{ fontSize: '0.25rem', marginBottom: '10px' }}>Withdrawn</div>
              <h5 style={{ fontSize: '17px', marginBottom: '0px' }}>{profileInfo.withdraw} TRX</h5>
            </div>
          </div>


        </div>
        <div className="row optMenu" style={{borderRadius:0,background:'#fff',height: 435,padding:'20px',marginTop:0 }} >
          <div className="col-4" style={{padding: '0px',  marginTop: '-10px' }} onClick={() => routeChange('RechargeWallet')}>
            <center> <img src="img/depositmoney.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>Deposit</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-10px' }} onClick={() => routeChange('withdraw')}>
            <center> <img src="img/withdrawmoney.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>Withdrawal</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-10px' }} onClick={() => routeChange('team')}>
            <center> <img src="img/community.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>Team</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={() => routeChange('income')}>

            <center> <img src="img/transaction.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px', color: '#000' }}>Transactions</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={() => routeChange('order')}>

            <center> <img src="img/investments.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>Investments</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={() => routeChange('reinvest')}>
            <center> <img src="img/economy (4).png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>ReInvest</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={() => window.open('https://t.me/#')}>
            <center> <img src="img/online-support.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>Support</div>
            </center>

          </div>
         
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={() => routeChange('AboutCompany')}>

            <center> <img src="img/companyinfo.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px', color: '#000' }}>Company</div>
            </center>

          </div>
          <div className="col-4" style={{ padding: '0px',  marginTop: '-40px' }} onClick={LogOut}>
            <center> <img src="img/signout.png" style={{ width: '55%' }} alt />
              <div style={{ fontSize: '14px' }}>LogOut</div>
            </center>

          </div>
        </div>
        {/* <div>
          <button className="copy_btn" style={{ border: 'none' }}><i className='fa fa-power-off' style={{ color: '#fff', fontSize: '20px' }}></i> Log Off</button>
        </div> */}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
         
          .myrow{
            margin-left:0px!important;
            margin-right:0px!important;
          }
          .optMenu{
            box-shadow:none;
          }
          .optMenu div{
            font-weight:bold;
          }
          .profileCard{
            padding: 5px;
            margin-bottom: 20px;
            margin-top: -23px;
            background-color: #ffc107;
            border-radius: 68px;
            color: #000;
          }
     .myheader{
         background: #212529;
         color: rgb(255, 255, 255);
         border-radius: 57px
     }
    //  body {
    //      background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
    //      animation: stars 250s linear alternate;
    //    }
    //    @keyframes stars {
    //      0% { background-position:0 0 }
    //      100% { background-position:0 100% }
    //    }
     .optionnew {
         padding: 10px;
         border-bottom: 1px solid #eee;
     }
     .blink_me {
         animation: blinker 1s linear infinite;
       }
       
       @keyframes blinker {
         50% {
           opacity: 0;
         }
       }
     .copy_btn {
         text-decoration: none;
         font-size: 16px;
         background: red !important;
         padding: 7px 0px;
         border-radius: 5px;
         color: #fff;
         display: block;
         margin: 0 auto;
         width: 50%;
     }
     .nav-tabs {
         display: flex;
         justify-content: space-evenly;
     }
     .nav-tabs>li>a {
         margin-right: 2px;
         line-height: 1.42857143;
         border: 1px solid transparent;
         border-radius: 4px 4px 0 0;
         border-bottom: 2px solid #ffc107;
         color: #000;
         font-size:17px;
         padding: 10px;
     }
     .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
         color: #fff;
         cursor: default;
         font-weight: bold;
         border: none;
         background-color: transparent;
         border-bottom: 2px solid #007bff;
         border-bottom-color: #007bff;
         text-decoration: none;
         background: #F76300 !important;
         border-bottom: 2px solid green;
     }
     .nav-tabs{
         border:0px !important;
     }
     .nav-tabs li{
         line-height:20px;
     }
`
        }}
      />
    </>
  );
}

export default UserProfile;